<template>
  <div id="account-management">
    <NForm inline label-placement="left">
      <NFormItem label="搜索标题：">
        <NInput
          maxlength="50"
          show-count
          clearable
          v-model:value="formValue.title"
          @update:value="ChangeTitle"
        />
      </NFormItem>
      <NFormItem label="类型：">
        <div style="width: 200px">
          <NSelect :options="typeOptions" v-model:value="formValue.type_status" />
        </div>
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>

    <n-button type="primary" class="account-btn" @click="NewlyAdded" v-if="ownUrlPermission('banner/create')">新增广告</n-button>
    <div class="account-null-btn"></div>
    <n-data-table
      remote
      ref="table"
      :single-line="false"
      :bottom-bordered="true"
      :columns="columns"
      :data="Datas"
      :loading="false"
      :pagination="pagination"
      @update:page-size="handlePageChange"
      @update:page="handlePageSizeChange"
    />

    <!-- 更改排序弹窗 -->
<div id="StoragePopup">
  <n-modal
    class="custom-card"
    v-model:show="showModal"
    preset="card"
    :style="{'width':'500px'}"
    title="更改排序"
    size="huge"
    :bordered="false"
  >
    <div>
      <n-input-number :show-button="false" v-model:value="storageNumber" max="999" min="1" style="width: 200px" @update:value="NumberSort"/>
      <p>当前排序：{{ storageNumber }}</p>
    </div>
    <div class="storage-btns">
      <NSpace>
        <NButton @click="CancelMethod(0)">取消</NButton>
        <NButton type="primary" @click="ChangeSort">确定</NButton>
      </NSpace>
    </div>
  </n-modal>
</div>
<!-- 更改排序弹窗结束 -->
    <!-- :row-key="rowKey" -->
    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineExpose, ref, h } from "vue";
import { NSpace, NButton, NTime, useMessage } from "naive-ui";

import teachingTypesMap from "@/enumerators/account-status.js";
import accountType from "@/enumerators/account-type";
import { resStatusEnum } from "@/enumerators/http.js";
import { GetBannerIndex, PostBannerState, PostBannerDelete, PostBannerSort } from "@/api/banner.js";  
import { toRaw } from '@vue/reactivity'
import { useRouter } from 'vue-router';
import PageLoading from "@/components/PageLoading/index.vue";
import ownUrlPermission from '@/utils/own-url-permission.js';

const message = useMessage();
const router = useRouter();
// 总数据
const Datas = ref([])
const columns =  [
    {
      title: "顺序",
      key: "sort",
    },
    {
      title: "标题",
      key: "title",
    },
    {
      title: "创建人",
      key: "admin_username",
    },
    {
    title: "创建时间",
    render: (row) =>
      h(NTime, {
        time: (Number(row.create_time) || 0) * 1000,
      }),
    },
    {
      title: '状态',
      render: row => h(
        'span',
        null,
        { default: () => getResourceName(row.type_status) },
      )
    },
    {
      title: "浏览数",
      key: "view_num",
    },
    {
      title: '操作',
      width: 300,
      key: "type_status",
      render: row => h(
        NSpace,
        null,
        {
          default: () => Operation(row)
        }
      )
    }
  ];

// 数据请求 / 分页所需--------------
const { SUCCESS } = resStatusEnum;
const loading = ref(false);

// 数据初始化
function updateList(data){
  loading.value = true;
  // 请求列表数据
  GetBannerIndex(data)
    .then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        Datas.value = res.data.list
        updateItemCount(res.data.total)
      }
    })
    .catch((reject) => {});
};
updateList(formValue);

// 状态
 function getResourceName(type_status) {
  return accountType[type_status] || '';
}

//操作
 function Operation(row) {
  let tempArr = [];
  let arr1 = h(NButton, { text: true, type: 'primary', onClick: () => SeeDetails(row) }, { default: () => '查看' })
  let arr2 = h(NButton, { text: true, type: 'error', onClick: () => toDelLabel(row) }, { default: () => '删除' })
  let arr3 = h(NButton, { text: true, type: 'primary', onClick: () => toAddLabel(row) }, { default: () => '编辑' })
  let arr4 = h(NButton, { text: true, type: 'primary', onClick: () => DisableEnable(row) }, { default: () => '启用' })
  let arr5 = h(NButton, { text: true, type: 'error', onClick: () => DisableEnable(row) }, { default: () => '停用' })
  let arr6 = h(NButton, { text: true, type: 'primary', onClick: () => CancelMethod(1, row) }, { default: () => '更改排序' })
  if(row.type_status=="0"){
    // 查看
    if (ownUrlPermission('banner/detail')) {
      tempArr.push(arr1);
    }
    // 删除
    if (ownUrlPermission('banner/delete')) {
      tempArr.push(arr2);
    }
    // 状态改变
    if (ownUrlPermission('banner/state')) {
      tempArr.push(arr4);
    }
    // 编辑
    if (ownUrlPermission('banner/update')) {
      tempArr.push(arr3);
    }
    return tempArr;
    }else{
      // 查看
      if (ownUrlPermission('banner/detail')) {
        tempArr.push(arr1);
      }
      // 更改排序
      if (ownUrlPermission('banner/sort')) {
        tempArr.push(arr6);
      }
      // 状态改变
      if (ownUrlPermission('banner/state')) {
        tempArr.push(arr5);
      }
      // 编辑
      if (ownUrlPermission('banner/update')) {
        tempArr.push(arr3);
      }
      return tempArr;
    }
}

const addLabelRef = ref(null);
// 编辑
function toAddLabel(row) {
  let id = row.id
  router.push({
    path:'/banner/index/addedit/',
    query:{id:id}
  })
}

// 新增
function NewlyAdded(row) {
  // console.log("编辑",row.id)
  router.push({
    path:'/banner/index/addedit'
  })
}

const NumberSort = ()=>{
  let num = storageNumber.value
  if(num === 0){
    message.info("排序值不能是0")
    storageNumber.value = 1
  }else if(num%1 != 0){
    message.info("排序值必须是整数")
    storageNumber.value = parseInt(num)
  }
}

// 查看
function SeeDetails(row) {
  // console.log("编辑",row.id)
  let id = row.id
  router.push({
    path:'/full-page/ad-details',
    query:{id:id}
  })

}

// 更改排序
function ChangeSort(){
  if(storageNumber.value !=''){
    let data = {
      'Banner[id]':showID.value,
      'Banner[sort]':storageNumber.value
    }
    PostBannerSort(data).then((res) => {
      if (res.code === SUCCESS) {
        showModal.value = false;
        loading.value = false;
        message.info(res.msg);
        // 成功后 调用接口刷新页面
        updateList(formValue)
      }
    })
    .catch((reject) => {})
  }else{
    message.info('排序不能为空');
  }

}

 // 删除
function toDelLabel(row) {
  const dialog = window.$dialog;
  let data = {}
  data.type_status = row.type_status
  data["Banner[id]"] = row.id
  dialog.warning({
    title: '删除确认',
    content: `确定删除:“${row.title}”？`,
    positiveText: '确定',
    negativeText: '取消',
    onPositiveClick: () => {
      loading.value = true;
      PostBannerDelete(data).then((res) => {
        if (res.code === SUCCESS) {
          loading.value = false;
          message.info(res.msg);
          // 成功后 调用接口刷新页面
          updateList(formValue)
        }
      })
      .catch((reject) => {})
      console.log("data",data)
    },
    onNegativeClick: () => {}
  });
}

const showModal = ref(false);  // 更改排序弹窗
const showID = ref('')        // 排序的ID
const storageNumber = ref(1);      // 排序

// 更改排序
function CancelMethod(is, row) {
  showModal.value = is?true:false;
  storageNumber.value = Number(row.sort);
  showID.value = row.id;
}

  // 停用启用
  function DisableEnable(row){
    loading.value = true;
    let data = {
      "Banner[id]":row.id,
      "Banner[type_status]":row.type_status == '0'?1 : 0
    }
    PostBannerState(data).then((res) => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.info(res.msg);
        // 成功后 调用接口刷新页面
        updateList(formValue)
      }
    })
    .catch((reject) => {});
  }

// 搜索
function handleSearch () {
  if(formValue.type_status=="1"){
    formValue.type_status = 0
  }
  else if(formValue.type_status=="2"){
    formValue.type_status = 1
  } 
  else if(formValue.type_status=="3"){
    formValue.type_status = 2
  }
  updateList(formValue)
}

// 监控标题输入
 function ChangeTitle(title) {
  pagination.page = 1;
  formValue.page = 1;
}

const pagination = reactive({
  page: 1,
  itemCount: 0,
  page_size: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;

};
const handlePageChange = (page_size) => {
  pagination.page_size = page_size;
  formValue.page_size = page_size;
  updateList(formValue);
};
const handlePageSizeChange = (page) => {
  pagination.page = page;
  formValue.page = page;
  updateList(formValue);
};

// 分页结束-------------------

const typeOptions = (() =>
  Object.keys(teachingTypesMap).map((value) => ({
    label: teachingTypesMap[value],
    value,
  })))();
const getDefaultFormValue = () => ({
  title: "",
  type_status: null,
  page: 1,
  page_size: 10,
});
const formValue = reactive(getDefaultFormValue());
const getSearchParams = () => {
  const params = {};
  Object.keys(formValue).forEach((key) => {
    const value = formValue[key];
    value && (params[key] = value);
  });
  return params;
};

const emit = defineEmits(["list-update"]);


const handleClear = () => {
  Object.assign(formValue, getDefaultFormValue());
  handleSearch();
};

defineExpose({
  getSearchParams,
});

</script>

<style scoped>
#account-management {
  height: 100%;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
}
.account-btn {
  float: right;
}
.account-null-btn {
  height: 50px;
  width: 120px;
}
</style>